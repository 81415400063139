<nav class="navbar navbar-default navbar-fixed-top">
  <div class="container-fluid">
    <div class="navbar-header">
        <button 
          type="button" 
          class="navbar-toggle collapsed" 
          data-toggle="collapse" 
          data-target="#navbar-collapse" 
          aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      <a class="navbar-brand" style="cursor: pointer;" (click)="onNotesList()">Firenotes</a>
    </div>
    <div class="collapse navbar-collapse" id="navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="!isAuthenticated()">
          <a 
            style="cursor: pointer;" 
            (click)="onLogin()" 
            data-toggle="collapse" 
            data-target="#navbar-collapse">Login</a>
        </li>
        <li *ngIf="isAuthenticated()">
          <a 
            style="cursor: pointer;" 
            (click)="onLogout()" 
            data-toggle="collapse" 
            data-target="#navbar-collapse">Logout</a>
        </li>
        <li>
          <a 
            style="cursor: pointer;" 
            (click)="onAbout()" 
            data-toggle="collapse" 
            data-target="#navbar-collapse">About</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="row">
	<div class="col-xs-12">
		<button 
			class="btn btn-primary"
			type="button"
			[routerLink]="['/note']">Add Note</button>
	</div>
</div>
<hr>
<div class="row">
	<div class="col-xs-12">
		<h4 *ngIf="isLoading" class="text-center">
			<span class="glyphicon glyphicon-refresh spinning"></span>  
			Loading...
		</h4>
		<app-note-list-item
			*ngFor="let note of notes; let i = index"
			[note]="note"></app-note-list-item>
	</div>
</div>
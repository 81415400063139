<div class="row">
  <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">About Firenotes</h3>
      </div>
      <div class="panel-body">
        <p>Firenotes is a demonstration of a 
          <a href="https://en.wikipedia.org/wiki/Single-page_application" target="_blank">single-page application (SPA)</a>
          using the <a href="https://angular.io/" target="_blank">Angular framework (v4)</a>,
          <a href="http://getbootstrap.com/" target="_blank">Bootstrap v3</a>, and 
          <a href="https://firebase.google.com/" target="_blank">Google Firebase</a> for authentication and database storage.</p>
        
        <p>Please note that this app is only for demo purposes, and 
        you should not store any notes here that you cannot afford to lose since the database may be purged from
        time to time.</p>

        <p>Source code can be found at <a href='https://github.com/d60402/firenotes'>https://github.com/d60402/firenotes</a></p>
        <p>&copy; 2017 Daryl Bergeron</p>
      </div>
    </div>
  </div>
</div>